/* Additional text stylings on page */

a {
    text-decoration: none;
}

.gitLink {
    color: white !important;
    text-decoration: underline;
}

small {
    color: white;
}

li .bi {
    color: aliceblue;
}

.fade-in {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
     
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        }
}

.fade-in-two {
    animation: fadeInAnimation2 ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
     
@keyframes fadeInAnimation2 {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        }
}

.fade-in-three {
    animation: fadeInAnimation3 ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
     
@keyframes fadeInAnimation3 {
    0% {
        opacity: 0;
    }

    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        }
}