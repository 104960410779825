

.contactWrapper {
    padding: 5px 20px;
    display: flex;
    text-align: center;
    flex-direction: column;
}

#formWrapper {
    display: flex;
    justify-content: center;
}

fieldset {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

}

label {
    margin: 10px;
}

label p {
    font-size: large;
}

textarea {
    word-wrap: break-word;
    word-break: break-all;
    overflow: scroll;
    white-space: normal;
    min-height: 25vh;
    width: 85%;
    border: 2px solid var(--brandYellow);
    border-style: inset;
    box-shadow: 0 0 1px #fff, 0 0 2px #000;
    background-color: var(--brandColorTextbox);
}

#formEntry {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    margin-bottom: 25px;

}

form h2 {
    margin: 35px 0;
}

.userContactInfo {
flex-grow: 1;

}

input {
    padding: 10px 5px;
    width: 100%;
    border: none;
    border: 2px solid var(--brandYellow);
    border-style: inset;
    box-shadow: 0 0 1px #fff, 0 0 2px #000;
    background-color: var(--brandColorTextbox);
}

#messageContainer {
    width: 100%;
}

form {
    width: 75%;
    background-color: var(--brandColorLight);
    color: white;
    padding: 15px;
    box-shadow: var(--bigBoxShadow);
    margin-bottom: 20px;
}


/* Contact Section */

#contactDetails {
    grid-area: contactDetails;
    display: flex;
    flex-wrap: wrap;
    gap:10px;
    justify-content: space-around;
}

.contactCards {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.contactCards p {
    user-select: all;
}

.contactCards:hover {
    transition: 0.3s;
    transform: scale(1.15);
}
/*
Media Query */

@media only screen and (max-width: 1024px){

    #formEntry {
        flex-direction: column;
    }

    form {
        width: 100%;
        padding: 1px;

    }

    form h2 {
        margin: 35px 15px;
    }

    form p {
        margin: 15px;
    }

    fieldset {
        margin: 0;
    }

}